<template>
    <div class="red_content">
        <div class="container_red">
          <img :src="tableData.activity_image"/>
            <div class="bottom_img" @click="introductionShow=true">
                <img src="../../assets/bottom.png" />
            </div>
            <!-- 活动简介 -->
            <introduction :tableData="tableData" @submitBtn="submitBtn" v-if="introductionShow"></introduction>
            <!-- 资料填写 -->
            <data-from :tableData="tableData" @cloneBtn="cloneBtn" v-if="dataShow"></data-from>
        </div>

        <div></div>
    </div>

</template>
<script>
import introduction from "../../components/active/introduction";
import dataFrom from "../../components/active/dataFrom";
import {unionActivity_api} from "@/api/deal"
export default {
  data() {
    return {
      dataShow:false,
      introductionShow:false,
      tableData:null
    };
  },
  components: {
    introduction,
    dataFrom
  },
  created() {
    document.title = "活动报名";
    this.getApply();
    if(JSON.parse(localStorage.getItem("dataFrom"))){
      this.dataShow = true
    }
  },
  methods: {
     getApply() {
      unionActivity_api().then(res=>{
        if(res.code==0){
          this.tableData = res.data
        }
      })
    },
    submitBtn(){
      this.dataShow = true;
      this.introductionShow = false
    },
    cloneBtn(){
      this.dataShow = false;
      this.introductionShow = false
    }
  }
};
</script>
<style>
.container_red {
  width: 100%;
  height: 100vh;
  box-sizing: border-box;
}
.container_red img{
  width:100%;
  height:100%
}
.bottom_img {
  position:fixed;
  bottom: 30px;
  left:50%;
  transform: translateX(-50%);
  width: 78px;
  height: 78px;
  margin: 0 auto;
}
.bottom_img img {
  width: 100%;
  height: 100%;
}
</style>
