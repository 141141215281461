<template>
  <div class="from_rule">
    <div class="header_top">活动详情介绍</div>
    <div v-html="tableData.activity_text"></div>
    <div class="submitBtn" @click="submitBtn">我要报名</div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  props: {
    tableData:{
      type:Object
    }
  },
  methods: {
    submitBtn(){
      this.$emit('submitBtn')
    }
  }
};
</script>
<style>
.from_rule {
  width: 635px;
  padding-bottom: 40px;
  border: 5px solid #fbd040;
  background: linear-gradient(180deg, #fdc56e 0%, #feeecd 100%);
  opacity: 1;
  border-radius: 38px;
  margin: 114px auto 0;
  position: fixed;
  top: 0px;
  left: 50%;
  transform: translateX(-50%);
  padding-top: 131px;
  padding-left: 68px;
  box-sizing: border-box;
}
.from_rule .header_top {
  width: 420px;
  height: 107px;
  border: 5px solid #fbe252;
  background: linear-gradient(180deg, #ee9c61 0%, #de3c07 100%);
  opacity: 1;
  border-radius: 36px;
  position: absolute;
  top: -34px;
  left: 108px;
  text-align: center;
  line-height: 107px;
  font-size: 50px;
  color: #fff;
}
.from_rule div {
  font-size: 34px;
  color: #c40d07;
  margin-bottom: 30px;
  font-weight: 800;
}
.from_rule .submitBtn {
  width: 327px;
  height: 74px;
  border: 5px solid #f1e54a;
  background: linear-gradient(180deg, #e9753c 0%, #e04205 100%);
  opacity: 1;
  border-radius: 42px;
  text-align:center;
  line-height:74px;
  margin: 200px 0 0 80px;
  color:#FFFAF6;
  font-size: 40px;
  letter-spacing:5px;
  text-align: center
}
</style>
