 <template>
  <div class="dataFrom">
    <img src="../../assets/fromData.png" />
    <img class="cloneBtn" @click="cloneBtn" src="../../assets/cloneGoods.png" />
    <div class="from_box">
      <div class="input_list">
        <div>{{dataFrom.userName.label}}：</div>
        <input v-model="dataFrom.userName.value" placeholder-class="" :placeholder="dataFrom.userName.placeholder" />
      </div>
      <div class="input_list">
        <div>{{dataFrom.phone.label}}：</div>
        <input @input="change" v-model="dataFrom.phone.value" placeholder-class="" :placeholder="dataFrom.phone.placeholder" />
      </div>
      <div class="input_list">
        <div>{{dataFrom.num.label}}：</div>
        <input v-model="dataFrom.num.value" placeholder-class="" :placeholder="dataFrom.num.placeholder" />
      </div>
      <div class="input_list">
        <div>{{dataFrom.message.label}}：</div>
        <input v-model="dataFrom.message.value" placeholder-class="" :placeholder="dataFrom.message.placeholder" />
      </div>
      <div class="addressBtn" @click="addressBtn">
        <img src="../../assets/Slice101.png" />
        <div>{{tableData.activity_adder}}</div>
      </div>
      <div class="address_item">
        <div>活动报名费:</div>
        <div class="price">￥{{tableData.cost_amount}}</div>
      </div>
      <div class="payBtn" @click="payBtn">点击支付</div>
    </div>
  </div>
</template>
 <script>
import { Activitysubmit_api } from "@/api/deal";
import { Toast } from "vant";
import * as apiCommon from "@/api/common";
import wx from "weixin-js-sdk";
export default {
  data() {
    return {
      dataFrom: {
        userName: { label: "姓名", placeholder: "请输入姓名", value: "" },
        phone: { label: "电话", placeholder: "请输入联系方式", value: "" },
        num: { label: "数量", placeholder: "请输入数量", value: "" },
        message: { label: "备注", placeholder: "请输入备注", value: "" }
      }
    };
  },
  props: {
    tableData: {
      type: Object
    }
  },
  components: {},
  created() {
    if(JSON.parse(localStorage.getItem("dataFrom"))){
      this.dataFrom = JSON.parse(localStorage.getItem("dataFrom"))
    }
  },
  methods: {
    change() {
      console.log(this.dataFrom);
    },
    cloneBtn(){
      this.$emit("cloneBtn")
    },
    addressBtn() {
      localStorage.setItem("dataFrom",JSON.stringify(this.dataFrom))
      this.$router.push(
        `/activity/map?lng=${this.tableData.address_dimension}&lat=${
          this.tableData.address_equatorial_circumference
        }&address=${this.tableData.activity_adder}`
      );
    },
    payBtn() {
      let data = {
        activity_id: this.tableData.id,
        apply_name: this.dataFrom.userName.value,
        apply_phone: this.dataFrom.phone.value,
        person_sum: this.dataFrom.num.value,
        remark: this.dataFrom.message.value
      };
      for (var i in data) {
        console.log(!data[i]);
        if (!data[i]) {
          this.$toast("请完善表单");
          return false;
        }
      }
      var myreg = /^[1][0-9]{10}$/;
      if (!myreg.test(this.dataFrom.phone.value)) {
        this.$toast("请输入正确的手机号");
        return false
      }
      console.log(this.tableData);
      Activitysubmit_api(data).then(res => {
        if (res.code == 0) {
          localStorage.setItem("activing_id",res.data.activity_id)
          localStorage.removeItem("dataFrom");
          console.log(res);
          if(this.tableData.cost_amount==0){
            this.$router.push(`/activity/success?id=${res.data.activity_id}`);
          }else{
            this.wxpay(res)
          }
          
        }else{
            this.$toast("暂时无法支付")
          }
      });
    },
    async wxpay(res){
      let that = this
      Toast.loading({
        message: "加载中...",
        forbidClick: true
      });
      let res1 = await apiCommon.getConfig();
      console.log(res1);
      let tableData = res1.data;
      wx.config(tableData);
      wx.chooseWXPay({
        timestamp: res.data.pay_info.timestamp, // 支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
        nonceStr: res.data.pay_info.nonceStr, // 支付签名随机串，不长于 32 位
        package: res.data.pay_info.package, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
        signType: "MD5", // 签名方式，默认为'SHA1'，使用新版支付需传入'MD5'
        paySign: res.data.pay_info.paySign, // 支付签名
        success(res) {
          if (res.errMsg == "chooseWXPay:ok") {
            let id = localStorage.getItem("activing_id")
            that.$toast("支付成功");
            that.$router.push(`/activity/success?id=${id}`);
          } else {
            alert(res.errMsg);
            that.$toast("支付失败", "middle");
          }
        },
        cancel: function(res) {
          that.$toast("支付取消", "middle", res);
        }
      });
    }
  }
};
</script>
 <style>
.dataFrom {
  width: 635px;
  height: 950px;
  position: fixed;
  top: 150px;
  left: 58px;
}
.dataFrom img {
  width: 100%;
  height: 100%;
}
.dataFrom .cloneBtn {
  position: absolute;
  top: 25px;
  right: 25px;
  width: 60px;
  height: 60px;
}

.from_box {
  position: absolute;
  top: 242px;
  left: 25px;
}

.addressBtn {
  font-size: 30px;
  color: #101010;
  display: flex;
  margin-top: 20px;
}

.from_box .addressBtn img {
  width: 24px;
  height: 29px;
  margin-right: 10px;
}

.address_item {
  display: flex;
  font-size: 30px;
  color: #101010;
  margin-left: 172px;
  margin-top: 41px;
}
.payBtn {
  width: 188px;
  height: 56px;
  border: 3px solid #f1e54a;
  background: linear-gradient(360deg, #e25015 0%, #fdd390 100%);
  opacity: 1;
  border-radius: 15px;
  text-align: center;
  line-height: 56px;
  font-size: 30px;
  color: #fff;
  margin: 28px auto 0;
}
.address_item .price {
  font-size: 35px;
  color: #fd4836;
}
.input_list {
  width: 600px;
  height: 90px;
  line-height: 90px;
  border-radius: 10px 10px 10px 10px;
  opacity: 1;
  border: 2px solid rgba(86, 59, 21, 0.3);
  color: #563b15;
  font-size: 32px;
  display: flex;
  padding-left: 22px;
  box-sizing: border-box;
  margin: 20px 0;
}
input {
  border: none;
  background: transparent;
  height: 45px;
  margin-top: 20px;
}
.van-toast {
  width: 300px;
  min-height: 120px;
  line-height:40px
}
</style>
 